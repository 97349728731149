import { useEffect, useState } from 'react';

import { useLayoutContext } from '@/contexts/Layout/LayoutContext';
import useCookie from '@/hooks/useCookie';

const Countdown = ({
  endDate, discount, description, promotionData,
}) => {
  const { countdown } = useLayoutContext();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [cookieValue, updateCookie] = useCookie('jfgCounterVisible');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const end = new Date(endDate);
      const diff = end.getTime() - now.getTime();

      // It will count backwards from 96
      setDays(Math.floor(diff / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((diff % (1000 * 60 * 60 * 24 * 4)) / (1000 * 60 * 60)));
      setMinutes(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((diff % (1000 * 60)) / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  const formatter = (number) => number.toLocaleString('en-US', { minimumIntegerDigits: 2 });

  const toggleCountdown = () => {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);
    updateCookie(!cookieValue, { expires: expireDate });
  };

  const moveDownStyle = { bottom: '0px', top: 'unset' };

  if (cookieValue) {
    return (
      <button type="button" className="jfg-countdown-open" onClick={toggleCountdown} style={countdown?.moveToBottom ? { ...moveDownStyle } : {}}>
        <div className="countdown-small-text">
          <span>{discount}</span>
        </div>
      </button>
    );
  }

  return (
    <div className="countdown-container" style={countdown?.moveToBottom ? { ...moveDownStyle } : {}}>
      <button
        id="jfg-countdown-close"
        type="button"
        className="countdown-hidden-button"
        onClick={toggleCountdown}
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
      >
        elrejtem
      </button>
      <div className="countdown-text">
        <div className="countdown-text-number">{discount}</div>
      </div>
      <div
        className="countdown-text-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="countdown__wrapper">

        { days > 0
        && (
        <div className="countdown__item">
          <div>
            <div className="countdown__item-number">
              <span>{formatter(days).split('')[0]}</span>
              <span>{formatter(days).split('')[1]}</span>
            </div>
            <div className="countdown__item-label">nap</div>
          </div>
        </div>
        )}
        <div className="countdown__item">
          <div>
            <div className="countdown__item-number">
              <span>{formatter(hours).split('')[0]}</span>
              <span>{formatter(hours).split('')[1]}</span>
            </div>
            <div className="countdown__item-label">óra</div>
          </div>
        </div>

        <div className="countdown__item">
          <div>
            <div className="countdown__item-number">
              <span>{formatter(minutes).split('')[0]}</span>
              <span>{formatter(minutes).split('')[1]}</span>
            </div>
            <div className="countdown__item-label">perc</div>
          </div>
        </div>

        <div className="countdown__item">
          <div>
            <div className="countdown__item-number">
              <span>{formatter(seconds).split('')[0]}</span>
              <span>{formatter(seconds).split('')[1]}</span>
            </div>
            <div className="countdown__item-label">mp</div>
          </div>
        </div>
      </div>

      <div className="countdown-box">
        <a href={promotionData.url} className="countdown-btn">{promotionData.btnLabel}</a>
      </div>
    </div>
  );
};

export default Countdown;
