import { useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import theme from '../../../styles/theme';
import JfgAdverticumZone from '../../marketing/AdverticumZone';
import RubiconMagnite from '../../marketing/RubiconMagnite/index.client';
import GateBanner from '../Banners/GateBanner';
import JfgFooter from '../Footer/Footer';
import Header from '../Header/index.client';
import ModalUI from '@/components/common/ModalUI';
import Countdown from '@/components/marketing/Countdown';
import {
  countdownFor,
  countdownStartDate,
  countdownEndDate,
  countdownDiscountPercentage,
  countdownDiscountText,
  countdownDisplaySubdomains,
  baseUrl,
  base2Url,
} from '@/config';
import LayoutContextWrapper from '@/contexts/Layout/LayoutContextWrapper';
import { useModalContext } from '@/contexts/Modal/ModalContext';
import { useUserContext } from '@/contexts/User/UserContext';
import { getAccountTermsAccepted } from '@/data/client/account';
import getUnreadMessagesCount from '@/data/client/messaging';
import { isValidDate } from '@/helpers/utils';

const MobileMenu = dynamic(
  () => import('../MobileMenu/index.client'),
);

const TermsChangedModal = dynamic(
  () => import('@/components/common/TermsChanged/TermsChangedModal'),
  { ssr: false },
);

const pagesWithoutLayout = ['/atiranyitas'];

const Layout = ({
  appl, category, vertical, children,
}) => {
  const intervalRef = useRef();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [subdomain, setSubdomain] = useState(null);

  const initLayoutContextValue = {
    countdown: {
      moveToBottom: false,
    },
  };

  const { pathname } = useRouter();

  const { userState } = useUserContext();
  const { account, token } = userState;

  const { showModal } = useModalContext();

  const countdownIsAvailableRef = useRef();

  const promotionData = {
    black_weekend: {
      url: `${baseUrl}/fiok/hirdeteseim`,
      btnLabel: 'Kiemelem a hirdetésem!',
    },
    job_promotion_ai: {
      url: `${base2Url}/ai`,
      btnLabel: 'Irány a hirdetésfeladás!',
    },
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { hostname } = window.location;
      setSubdomain(hostname.split('.')[0]);
    }
  }, []);

  useEffect(() => {
    if (account) {
      getAccountTermsAccepted({ token }).then((terms) => {
        if (terms?.termsAccepted === false) {
          showModal(TermsChangedModal, { account, terms, token });
        }
      });

      const fetch = async () => {
        try {
          const res = await getUnreadMessagesCount({ token });
          setUnreadMessages(Math.max(res?.unread || res?.unread_message_count, 0));
        } catch (error) {
          clearInterval(intervalRef.current);
        }
      };
      fetch();

      intervalRef.current = setInterval(fetch, 30000);
    }
    return () => clearInterval(intervalRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, token]);

  useEffect(() => {
    const excludedPathNames = [
      '/',
      '/auto',
      '/ingatlan',
      '/belepes',
      '/regisztracio',
      '/regisztracio/megerosites',
      '/szallitas/rendeles/[listId]',
      '/adathalaszat',
    ];
    const now = new Date();

    // if there is countdownDisplaySubdomains then no need to check for excluded pathNames
    // because it will appear only on the specified subdomains
    const routeMatchCheck = () => {
      if (countdownDisplaySubdomains.length) {
        return countdownDisplaySubdomains.includes(subdomain);
      }
      return !excludedPathNames.includes(pathname);
    };

    countdownIsAvailableRef.current = routeMatchCheck()
      && isValidDate(countdownStartDate)
      && isValidDate(countdownEndDate)
      && new Date(countdownStartDate) < now
      && new Date(countdownEndDate) > now;
  });

  if (pagesWithoutLayout.includes(pathname)) {
    return children;
  }

  return (
    <LayoutContextWrapper {...initLayoutContextValue}>
      <GateBanner pathname={pathname} appl={appl} category={category} />
      <Grid container direction="column" id="page-content" sx={{ position: 'relative', minHeight: '100vh' }}>
        <Header
          pathname={pathname}
          unreadMessages={unreadMessages}
          openSideMenu={() => setShowMobileMenu(true)}
          vertical={vertical}
        />
        <MobileMenu
          showMobileMenu={showMobileMenu}
          closeMobileMenu={() => setShowMobileMenu(false)}
          unreadMessages={unreadMessages}
        />
        <Grid container item direction="column" alignItems="center" flexGrow={1} sx={{ bgcolor: theme.palette.background.default }}>
          {children}
        </Grid>
        {(pathname.indexOf('fiok/postalada') < 0) && <JfgFooter />}
        <ModalUI />
        <RubiconMagnite />
      </Grid>
      {countdownIsAvailableRef.current && (
        <Countdown
          promotionData={promotionData[countdownFor]}
          endDate={countdownEndDate}
          discount={countdownDiscountPercentage}
          description={countdownDiscountText}
        />
      )}
      <JfgAdverticumZone style={{ display: 'none' }} pageType="global" zoneName="common_stat_zone_1" displayOn="all" />
      <JfgAdverticumZone style={{ display: 'none' }} pageType="global" zoneName="common_stat_zone_2" displayOn="all" />
      <JfgAdverticumZone style={{ display: 'none' }} pageType="global" zoneName="popup" displayOn="all" />
    </LayoutContextWrapper>
  );
};

export default Layout;
